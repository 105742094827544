import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmacionComponent } from './confirmacion.component';

/**
 * Contine una referencia al modal abierto
 */
export class ModalConfirmar {
  public instance: NgbModalRef;

  /**
   * Este es el constructor que tendra los parametros
   * @param modalService Es el servicio de modal.
   * @param servicio El servicio. Este campo tiene que inplementar la interface EliminarInterfaz
   * @param titulo El titulo a poner en el modal
   * @param  confirmar Nombre de la función custom de eliminar (Si existe)
   * @param cancelar Nombre de la función custom de eliminar (Si existe)
   */
  constructor(private modalService: NgbModal, titulo: string, confirmar: string, cancelar: string, body: any) {


    this.instance = this.modalService.open(ConfirmacionComponent, { backdrop: 'static' });

    titulo = titulo ? titulo : '¿Desea realizar esta acción?';
    confirmar = confirmar ? confirmar : 'Aceptar';
    cancelar = cancelar ? cancelar : 'Cancelar';

    this.instance.componentInstance.titulo = titulo;
    this.instance.componentInstance.confirmar = confirmar;
    this.instance.componentInstance.cancelar = cancelar;
    this.instance.componentInstance.body = body;

  }
}
