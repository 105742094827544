import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AuthGuard } from '../../../@seguridad/servicios/authGuard.service';
import { AutenticacionService } from '../../../@seguridad/servicios/autenticacion.service';
import { Menu } from '../../../modulos/modulos-menu';
import { ModalConfirmar } from '../confirmacion/modal-confirmar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfilComponent } from './perfil/perfil.component';
import { PermisosService } from '../../../@seguridad/servicios/permisos.service';
import { environment } from '../../../../environments/environment';
@Component({
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['sidebar.component.scss'],
    animations: [
        trigger('slide', [
            state('up', style({ height: 0 })),
            state('down', style({ height: '*' })),
            transition('up <=> down', animate(0))
        ]),
        trigger('slide-perfil', [
            state('up', style({ height: 0 })),
            state('down', style({ height: '*' })),
            transition('up <=> down', animate(0))
        ])
    ]
})

export class SidebarComponent implements OnInit {
    public currentUser;
    public pathImagen:string = environment.apiUrl
    public statePerfil: string;
    public menuItems: any[];
    @Input() menu: Menu[];


    constructor(
        private authGuard: AuthGuard,
        private auth: AutenticacionService,
        private modalService: NgbModal,
        private permisosService: PermisosService
    ) {
        this.currentUser = this.authGuard.CurrentUser();
        this.statePerfil = 'up';
    }

    ngOnInit() {
        this.menuItems = this.menu;
    }

    ob() {
        return this.menuItems;
    }

    toggle(currentMenu: Menu) {
        if (currentMenu.SubMenus && currentMenu.SubMenus.length > 0) {
            this.actualizarEstaItem(currentMenu);
        } else {
            this.actualizarEstaItem(currentMenu);
            this.clickMenu(currentMenu);
        }
    }

    getState(currentMenu: Menu) {
        if (currentMenu.Activo) {
            return 'down';
        } else {
            return 'up';
        }
    }

    clickMenu(currentMenu: Menu) {
        if (currentMenu.SubMenus.length == 0) {
            localStorage.setItem('PaginaActual', currentMenu.Nombre);
        }
    }

    actualizarEstaItem(currentMenu: Menu) {
        this.menuItems.forEach((element) => {
            if (element === currentMenu) {
                currentMenu.Activo = !currentMenu.Activo;
            } else {
                element.Activo = false;
            }
        });
    }

    setStatePerfil() {
        if (this.statePerfil == 'up') {
            this.statePerfil = 'down';
        } else {
            this.statePerfil = 'up';
        }
    }

    cerrarSesion() {
        const modal = new ModalConfirmar(this.modalService, 'Cerrar sesión', 'Confirmar', 'Cancelar', '¿Desea cerrar session?').instance;
        modal.componentInstance.hecho.subscribe((result) => {
            if (result) {
                this.auth.logout();
            }
        });
    }

    perfil() {
		const modal = this.modalService.open(PerfilComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.id = this.currentUser.IdUsuario;
        modal.componentInstance.rol = this.currentUser.NombreRol;
		modal.result.then((respuesta) => {
			//this.obtenerMenus();
		});
    }
    
    comprobarPermisoMenu(permiso: string){
        return this.permisosService.comprobarPermiso(permiso);
    }

}
