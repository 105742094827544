import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorService } from '../../@seguridad/servicios/error.service';
import { environment } from '../../../environments/environment';
import { GenericResponse } from '../../modelos/GenericResponse/GenericResponse.model';


@Injectable()
export class RolService {
    private url = environment.apiUrl + '/rol';
    private body: any;

    constructor(
        private http: HttpClient,
        private errorService: ErrorService
    ) { }

    get(): Observable<GenericResponse> {
        return this.http.get<any>(this.url).pipe(
            map(
                (result: GenericResponse) => {
                    return result;
                },
                (error: any) => {
                    this.errorService.handleError(error);
                }
            )
        );
    }


    getxId(id): Observable<GenericResponse> {
        return this.http.get<any>(this.url, {
            headers: new HttpHeaders().set('content-type', 'application/json'),
            params: {
                'IdRol': id
            }
        })
            .pipe(map((result: GenericResponse) => {
                return result;
            }, error => {
                this.errorService.handleError(error);
            }
            ))
    }


    post(datos): Observable<GenericResponse> {
        this.body = JSON.stringify(datos);

        return this.http.post<GenericResponse>(
            this.url,
            this.body,
            { headers: new HttpHeaders().set('content-type', 'application/json') })
            .pipe(map((result: GenericResponse) => {
                return result;
            }, error => {
                this.errorService.handleError(error);
            }
            ))

    }

    put(datos): Observable<GenericResponse> {
        this.body = JSON.stringify(datos);

        return this.http.put<GenericResponse>(
            this.url,
            this.body,
            { headers: new HttpHeaders().set('content-type', 'application/json') })
            .pipe(map((result: GenericResponse) => {
                return result;
            }, error => {
                this.errorService.handleError(error);
            }
            ))

    }

    delete(id): Observable<GenericResponse> {
        return this.http.delete<any>(this.url, {
            headers: new HttpHeaders().set('content-type', 'application/json'),
            params: {
                'IdRol': id
            }
        })
            .pipe(map((result: GenericResponse) => {
                return result;
            }, error => {
                this.errorService.handleError(error);
            }
            ))
    }

}