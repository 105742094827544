import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SubheaderComponent } from './subheader.component';
@NgModule({
    imports: [ RouterModule, CommonModule ],
    declarations: [ SubheaderComponent ],
    exports: [ SubheaderComponent ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class SubHeaderModule {}