<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header row">

        <div class="col-md-6 col-12">
          <h5 class="card-title">
            <ng-content #title select="[titulo]"> </ng-content>
          </h5>
        </div>

        <div class="col-md-2 col-12">
          <ng-content #actions select="[botones]"> </ng-content>
        </div>

        <div class="col-md-4 col-12">
          <ng-content #search select="[busqueda]"></ng-content>
        </div>

      </div>
    </div>
  </div>
</div>
<div>
