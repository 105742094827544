import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificacionesConfig } from '../../../@temas/notificaciones/notificaciones';
import { ErrorService } from '../../../@seguridad/servicios/error.service';
import { EliminarInterfaz } from './eliminar-interfaz';

import 'jquery';
declare var HoldOn: any;

@Component({
  selector: 'app-eliminar',
  templateUrl: './eliminar.component.html'
})
export class EliminarComponent implements OnInit {
  @Input() service: EliminarInterfaz;
  @Input() id: number;
  @Input() titulo: string;
  @Input() nombreFuncion: string;
  @Output() hecho: EventEmitter<boolean> = new EventEmitter();
  // @ViewChild('borrar', { static: true }) botonBorrar: ElementRef;

  constructor(public activeModal: NgbActiveModal, private notificacion: NotificacionesConfig, private errorService: ErrorService) { }

  ngOnInit() {
    // this.botonBorrar.nativeElement.focus();
  }

  public retornarTitulo(): string {
    return this.titulo;
  }

  public cerrarModal() {
    this.activeModal.close();
  }

  public eliminarRegistro() {
    HoldOn.open();
    this.service.delete(this.id).pipe().subscribe(
      (result) => {
        if (result.exito) {
          this.hecho.emit(result.exito);
          this.activeModal.close();
          this.notificacion.success(result.mensaje);
          HoldOn.close();
        } else {
          HoldOn.close();
          this.notificacion.warning(result.mensaje);
        }
      }, (error) => {
        HoldOn.close();
        this.errorService.handleError(error);
      }
    );
  }

  public eliminarRegistroFuncionCustom() {
    HoldOn.open();
    this.service[this.nombreFuncion](this.id).pipe().subscribe(
      (result) => {
        if (result.exito) {
          this.hecho.emit(result.exito);
          this.activeModal.close();
          this.notificacion.success(result.mensaje);
          HoldOn.close();
        } else {
          HoldOn.close();
          this.notificacion.warning(result.mensaje);
        }
      }, (error) => {
        HoldOn.close();
        this.errorService.handleError(error);
      }
    );
  }
}
