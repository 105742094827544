import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { HoldOnConfig } from './@temas/holdOn/holdOn';
import { NotificacionesConfig } from './@temas/notificaciones/notificaciones';
import { HttpClientModule } from '@angular/common/http';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { AppComponent } from './app.component';
import { AutenticacionService } from './@seguridad/servicios/autenticacion.service';
import { AuthGuard } from './@seguridad/servicios/authGuard.service';
import { PermisosService } from './@seguridad/servicios/permisos.service';

import { TemasModule } from './@temas/temas.module'
import { DataTablesModule } from 'angular-datatables';
import { environment } from '../environments/environment';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { EditarTarifaMaxComponent } from './@componentes/editar-tarifa-max/editar-tarifa-max.component';
export function tokenGetterFactory() {
  return localStorage.getItem('UsuarioToken');
}

export function ValidacionPermisoFactory(provider: PermisosService) {

  return async () => {
    const jwt = new JwtHelperService();
    if (localStorage.UsuarioToken && !jwt.isTokenExpired(localStorage.UsuarioToken)) {
      await provider.obtenerMenu().then((res) => {
        provider.setMenu(res['datos']);
      });

      await provider.ObtenerPermisos().then((res) => {
        provider.setPermisos(res['datos']);
      });


    } else {
      provider.logout();
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    EditarTarifaMaxComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    PdfViewerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetterFactory,
        whitelistedDomains: environment.whiteListUrl
      }
    }),
    DataTablesModule,
    TemasModule.forRoot()
  ],
  providers: [
    HoldOnConfig,
    NotificacionesConfig,
    AutenticacionService,
    AuthGuard,
    PermisosService,
    {
      provide: APP_INITIALIZER,
      useFactory: ValidacionPermisoFactory,
      multi: true,
      deps: [PermisosService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
