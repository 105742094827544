import * as XLSX from 'xlsx';
export function generadorContrasenia(): any {
    const caracteres = {
        numeros: '0 1 2 3 4 5 6 7 8 9',
        simbolos: '! @ # $ % ^ & * ( ) _ - + = { [ } ] ; : < , > . ? /',
        mayusculas: 'A B C D E F G H I J K L M N O P Q R S T U V W X Y Z',
        minusculas: 'a b c d e f g h i j k l m n o p q r s t u v w x y z'
    }

    var configuracion = {
        caracteres: parseInt('8'),
        simbolos: false,
        numeros: true,
        mayusculas: true,
        minusculas: true
    }

    let caracteresFinales: any = '';
    let password: any = '';

    for (let propiedad in configuracion) {
        if (configuracion[propiedad] == true) {
            caracteresFinales += caracteres[propiedad] + ' ';
        }
    }
    caracteresFinales = caracteresFinales.trim();
    caracteresFinales = caracteresFinales.split(' ');
    for (var i = 0; i < configuracion.caracteres; i++) {
        password += caracteresFinales[Math.floor(Math.random() * caracteresFinales.length)];
    }

    return password;

}

export async function generalJSON(e) {

    return new Promise<any>((resolve, reject) => {
        let workBook = null;
        let jsonData = null;
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onload = function (event) {
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            resolve(jsonData);
        };
        reader.readAsBinaryString(file);
    });

}