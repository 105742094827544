import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorService } from '../../../@seguridad/servicios/error.service';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html'
})
export class ConfirmacionComponent implements OnInit {
  @Input() titulo: string;
  @Input() confirmar: string;
  @Input() cancelar: string;
  @Input() body: string;
  @Output() hecho: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('btnCancelar', { static: true }) btnCancelar: ElementRef;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.btnCancelar.nativeElement.focus();
  }

  public cerrarModal(validacion: boolean) {
    this.hecho.emit(validacion);
    this.activeModal.close();
  }

}
