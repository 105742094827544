<nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-toggle">
        <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
    </div>
    <button class="navbar-toggler" type="button" (click)="collapse()" [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="isCollapsed">
      <form>
        <!--div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="nc-icon nc-zoom-split"></i>
            </div>
          </div>
        </div-->
      </form>
      <ul class="navbar-nav">

        <li *ngFor="let item of menuItems" class="nav-item btn-rotate" ngbDropdown placement="bottom-left">
          <a class="nav-link" ngbDropdownToggle id="navbarDropdownMenuLink">
            <i [class]="item.Icono"></i>
            <p>
              <span class="d-lg-none d-md-block">{{item.Nombre}}</span>
            </p>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-right">
            <a *ngFor="let s of item.SubMenus" ngbDropdownItem [routerLink]="s.Url" >{{s.Nombre}}</a>
          </div>
        </li>


        <li class="nav-item">
          <a class="nav-link btn-rotate" (click)="cerrarSesion()">
            <i class="fa fa-sign-out"></i>
            <p>
              <span class="d-lg-none d-md-block">Cerrar sesión</span>
            </p>
          </a>
        </li>


      </ul>
    </div>
  </div>
</nav>
