import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { NavbarModule } from './componentes/navbar/navbar.module';
import { SidebarModule } from './componentes/sidebar/sidebar.module';
import { SubHeaderModule } from './componentes/subheader/subheader.module';
import { FiltroPipe } from './componentes/filtro/filtro-pipe.component';
import { EliminarComponent } from './componentes/eliminar/eliminar.component';
import { ConfirmacionComponent } from './componentes/confirmacion/confirmacion.component';

@NgModule({
    imports: [CommonModule, RouterModule, NgbModule, FormsModule, ReactiveFormsModule, NgSelectModule],
    exports: [FormsModule, ReactiveFormsModule, FiltroPipe, SidebarModule, NavbarModule, SubHeaderModule, NgxPaginationModule, NgSelectModule, EliminarComponent],
    declarations: [FiltroPipe, EliminarComponent, ConfirmacionComponent],
    entryComponents: [EliminarComponent, ConfirmacionComponent],
    providers: []
})

export class TemasModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: TemasModule
        } as ModuleWithProviders;
    }
}