import { Injectable } from '@angular/core';
import { CanActivate, Route, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Router } from '@angular/router';
import { AutenticacionService } from './autenticacion.service';
import { PermisosService } from './permisos.service';
import { UsuarioModel } from '../modelos/usuario.model';
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private authService: AutenticacionService,
        private permisosService: PermisosService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url = state.url;
        // Verifica si hay un usuario autenticado
        if (!this.checkLogin(url)) {
            return false; // retorn false para no acceder al sistema
        }

        let tienePermiso = true;

        if (route.data.permiso != null) {
            tienePermiso = this.permisosService.comprobarPermiso(route.data.permiso);
        }
        if (tienePermiso) {
            return true;
        } else {
            this.router.navigateByUrl('/sin-acceso');
            return false;
        }
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(childRoute, state);
    }

    canLoad(route: Route): boolean {
        const url = `/${route.path}`;
        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {
        if (this.authService.isLogged()) {
            return true;
        }

        // Navigate to the login page with extras
        this.router.navigate(['/sesion'], {
            queryParams: {
                return: url
            }
        });

        this.authService.logout();
        return false;
    }

    CurrentUser(): UsuarioModel {
        return JSON.parse(this.authService.getUsuarioActual());
    }
}