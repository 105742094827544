import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'sesion',
    loadChildren: () => import('./@seguridad/sesion/sesion.module').then((m) => m.SesionModule)
  },
  {
    path: 'restaurar-contrasena',
    loadChildren: () => import('./@seguridad/restaurar-contrasena/restaurar-contrasena.module').then((m) => m.RestaurarContrasenaModule)
  },
  {
    path: 'sin-acceso',
    loadChildren: () => import('./@seguridad/sin-acceso/sin-acceso.module').then((m) => m.SinAccesoModule)
  },
  {
    path: 'modulo',
    loadChildren: () => import('./modulos/modulo.module').then((m) => m.ModuloModule)
  },
  {
    path: '',
    redirectTo: 'modulo',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: 'sin-acceso' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
