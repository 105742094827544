import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ErrorService } from 'src/app/@seguridad/servicios/error.service';
import { UsuariosService } from '../../../../@servicios/usuarios/usuarios.service';
import { RolService } from '../../../../@servicios/rol/rol.service';
import { EstadosUsuariosService } from '../../../../@servicios/estado-usuario/estado-usuario.service';
import { HoldOnConfig } from '../../../../@temas/holdOn/holdOn';
import { NotificacionesConfig } from '../../../../@temas/notificaciones/notificaciones';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Roles } from '../../../../modelos/Roles.model';
import { EstadosUsuarios } from '../../../../modelos/EstadosUsuario.model';
import { environment } from '../../../../../environments/environment';
import { generadorContrasenia } from '../../../../@servicios/utilidades.const';

@Component({
    selector: 'app-perfil',
    templateUrl: './perfil.component.html'
})


export class PerfilComponent implements OnInit {

    @Input() id: any;
    @Input() rol: any;
    public pathImagen: string;
    public form: FormGroup;
    public EstadosUsuarios: EstadosUsuarios[];
    public Roles: Roles[];
    private formData: FormData;

    constructor(
        private service: UsuariosService,
        private rolService: RolService,
        private estadosUsuariosService: EstadosUsuariosService,
        private holdOn: HoldOnConfig,
        private notificacion: NotificacionesConfig,
        private formBuiler: FormBuilder,
        public modalActivo: NgbActiveModal,
        private errorService: ErrorService,
    ) {
        this.pathImagen = '';
    }

    ngOnInit() {
        this.obtenerFormulario();
    }

    async obtenerFormulario() {
        this.form = this.formBuiler.group({
            IdUsuario: [null],
            IdRol: [null, Validators.required],
            IdEstadoUsuario: [null, Validators.required],
            NombrePersonal: [null, Validators.compose([Validators.required, Validators.maxLength(150), Validators.minLength(2)])],
            NombreUsuario: [null, Validators.compose([Validators.required, Validators.maxLength(150), Validators.minLength(2)])],
            Contrasena: [null, Validators.compose([Validators.required, Validators.maxLength(20), Validators.minLength(6)])],
            CorreoElectronico: [null, Validators.compose([
                Validators.required,
                Validators.maxLength(50),
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
            ],
            Telefono: [null, Validators.compose([Validators.maxLength(20), Validators.minLength(7)])],
            Imagen: [null]
        });
        await this.obtenerInformacion();
    }


    async obtenerInformacion() {
        this.holdOn.open('Cargando..!');
        await Promise.all(
            [
                this.rolService.get().toPromise(),
                this.estadosUsuariosService.get().toPromise(),
            ]
        ).then(async resultado => {
            this.Roles = resultado[0].datos;
            this.EstadosUsuarios = resultado[1].datos;
        });

        this.obtenerUsuario();
        this.holdOn.close();
    }

    async obtenerUsuario() {
        this.form.controls.Contrasena.setValidators(
            Validators.compose([
                Validators.maxLength(20),
                Validators.minLength(6)
            ])
        );
        const respuesta = await this.service.getxId(this.id).toPromise();
        this.form.patchValue({
            IdUsuario: respuesta.datos.IdUsuario,
            IdRol: respuesta.datos.IdRol,
            IdEstadoUsuario: respuesta.datos.IdEstadoUsuario,
            NombrePersonal: respuesta.datos.NombrePersonal,
            NombreUsuario: respuesta.datos.NombreUsuario,
            Contrasena: null,
            CorreoElectronico: respuesta.datos.CorreoElectronico,
            Telefono: respuesta.datos.Telefono,
            Imagen: respuesta.datos.Imagen
        });

        if (respuesta.datos.Imagen == null) {
            this.pathImagen = '';
        } else {
            this.pathImagen = environment.apiUrl + '/' + respuesta.datos.Imagen;
        }
    }
    eliminarImagen() {
        this.pathImagen = '';
        this.form.patchValue({
            Imagen: null
        });
    }

    public uploadFile = (files) => {
        if (files.length === 0) {
            return;
        }

        let fileToUpload = <File>files[0];
        this.formData = new FormData();
        this.formData.append('imagen', fileToUpload, fileToUpload.name);
        this.service.subirImagen(this.formData).pipe().subscribe(
            (result) => {
                if (result.exito) {
                    this.pathImagen = environment.apiUrl + '/' + result.datos;
                    this.form.patchValue({
                        Imagen: result.datos
                    });
                    //this.subiendoNuevo = false;
                } else {
                    this.notificacion.warning(result.mensaje);
                    //this.subiendoNuevo = false;
                }
            }, (error) => {
                this.errorService.handleError(error);
                //this.subiendoNuevo = false;
            }
        );
    }

    generalContrasenia() {
        this.form.patchValue({
            Contrasena: generadorContrasenia()
        });
    }

    actualizar() {
        this.holdOn.open('Cargando..!');
        this.service.put(this.form.value).pipe().subscribe(
            (result) => {
                if (result.exito) {
                    this.notificacion.success(result.mensaje);
                    this.form.reset();
                    this.holdOn.close();
                    this.modalActivo.close(true);
                } else {
                    this.notificacion.error(result.mensaje);
                    this.holdOn.close();
                }
            }, (error) => {
                this.errorService.handleError(error);
                this.holdOn.close();
            }
        );
    }

    cerrarModal() {
        this.modalActivo.close(true);
    }

}