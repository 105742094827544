import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filtro',
    pure: false
})
export class FiltroPipe implements PipeTransform{

    transform(items: any[], filter: any, listaCampoFiltrar: any): any {
        if (!items || !filter || !listaCampoFiltrar) {
            return items;
        }

        let listaElementoFiltrado = [];

        listaCampoFiltrar.forEach(campo => {
            const listaElementoEncontrado =
                items.filter(
                    x =>
                        x[campo] ?
                            x[campo].toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1 :
                            false
                );

            listaElementoFiltrado = listaElementoFiltrado.concat(listaElementoEncontrado);
        });

        return Array.from(new Set(listaElementoFiltrado));
    }

}