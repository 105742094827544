import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificacionesConfig {

    constructor(private toaster: ToastrService) {
    }

    info(mensaje: string) {
        this.toaster.info(
            '<span data-notify="icon" class="nc-icon nc-bell-55"></span>'+
            '<span data-notify="message">'+
            '<b>Información</b><br>'+mensaje+            
            '</span>',
            '',
            {
                timeOut: 4000,
                closeButton: true,
                enableHtml: true,
                toastClass: "alert alert-info alert-with-icon",
                positionClass: "toast-top-right"
            }
        );
    }

    error(mensaje: string) {
        this.toaster.error(
            '<span data-notify="icon" class="nc-icon nc-simple-remove"></span>'+
            '<span data-notify="message">'+
            '<b>Error</b><br>'+mensaje+            
            '</span>',
            '',
            {
                timeOut: 4000,
                closeButton: true,
                enableHtml: true,
                toastClass: "alert alert-danger alert-with-icon",
                positionClass: "toast-top-right"
            }
        );
    }

    warning(mensaje: string) {
        this.toaster.warning(
            '<span data-notify="icon" class="nc-icon nc-alert-circle-i"></span>'+
            '<span data-notify="message">'+
            '<b>Advertencia</b><br>'+mensaje+            
            '</span>',
            '',
            {
                timeOut: 4000,
                closeButton: true,
                enableHtml: true,
                toastClass: "alert alert-warning alert-with-icon",
                positionClass: "toast-top-right"
            }
        );
    }

    success(mensaje: string) {
        this.toaster.success(
            '<span data-notify="icon" class="nc-icon nc-check-2"></span>'+
            '<span data-notify="message">'+
            '<b>Exito</b><br>'+mensaje+            
            '</span>',
            '',
            {
                timeOut: 4000,
                closeButton: true,
                enableHtml: true,
                toastClass: "alert alert-success alert-with-icon",
                positionClass: "toast-top-right"
            }
        );
    }


}