<div class="sidebar-wrapper">
  <div class="logo">
    <a routerLink="/modulo" class="simple-text logo-normal">
      <div class="logo-image-small">
        <img src="assets/imagenes/logo-v2.png">
      </div>
    </a>
  </div>
  <div class="logo">
    <div class="info">
      <img [src]="currentUser.Imagen? pathImagen + '/' + currentUser.Imagen:'assets/imagenes/user.jpg'">
      <a (click)="setStatePerfil()">
        {{currentUser.NombrePersonal}}
        <b class="fa fa-caret-down"></b>
      </a>
      <ul class="nav info-panel" [@slide-perfil]="statePerfil">
        <li>
          <a class="title-Submenu" (click)="perfil()">
            <i class="fa fa-user"></i>
            Perfil
          </a>
        </li>
        <li>
          <a class="title-Submenu" (click)="cerrarSesion()">
            <i class="fa fa-sign-out"></i>
            Cerrar sesión
          </a>
        </li>
      </ul>

    </div>

  </div>
  <ul class="nav">

    <li *ngFor="let item of menuItems"
      [ngClass]="{'active': item.Activo, 'sidebar-dropdown': item.SubMenus.length > 0 }">
      <a (click)="toggle(item)" [routerLink]="item.Url">
        <i [class]="item.Icono"></i>
        <p class="title-menu">
          {{item.Nombre}}
          <b *ngIf="item.SubMenus.length > 0" class="fa fa-caret-down"></b>
        </p>
      </a>
      <div *ngIf="item.SubMenus.length > 0" class="sidebar-submenu" [@slide]="getState(item)">

        <ul *ngIf="item.SubMenus.length > 0" style="padding-left: 10px;">
          <li *ngFor="let s of item.SubMenus" [ngClass]="{'active': s.Activo}">
            <a class="title-Submenu" [routerLink]="s.Url" (click)="clickMenu(s)" *ngIf="comprobarPermisoMenu(s.IdPermiso)">
              {{s.Nombre}}
            </a>
          </li>
        </ul>
      </div>
    </li>

  </ul>
</div>
