export class UsuarioModel {
    IdUsuario: string;
    IdRol: string;
    NombreRol: string;
    IdEstadoUsuario:string;
    NombreEstadoUsuario: string;
    NombrePersonal: string;
    NombreUsuario: string;
    Imagen: string;
    autenticado:boolean;
    mensaje:string;
    permisos: string[];
}
