import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { UsuarioModel } from '../modelos/usuario.model';
import { GenericResponse } from '../../modelos/GenericResponse/GenericResponse.model';
import { ErrorService } from './error.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AutenticacionService {
    private token: string;
    private apiUrl = environment.apiUrl + '/sesion';
    private body: any;
    private usuarioModel: UsuarioModel;
    private usuarioActualSubject: BehaviorSubject<UsuarioModel>;

    constructor(
        private http: HttpClient,
        private errorService: ErrorService,
        private router: Router
    ) {
        this.usuarioActualSubject = new BehaviorSubject<UsuarioModel>(
            JSON.parse(localStorage.getItem('UsuarioActual'))
        );
    }

    authenticate(nombreUsuario: string, contrasena: string): Observable<UsuarioModel> {
        this.logout();
        this.body = JSON.stringify({ nombreUsuario: nombreUsuario, contrasena: contrasena });

        return this.http
            .post<GenericResponse>(this.apiUrl, this.body, {
                headers: new HttpHeaders().set('content-type', 'application/json')
            }).pipe(
                map(
                    (response: GenericResponse) => {
                        if (response.exito) {
                            this.token = response.datos.token;

                            this.usuarioModel = new UsuarioModel();
                            this.usuarioModel.IdUsuario = response.datos.IdUsuario;
                            this.usuarioModel.NombreRol = response.datos.NombreRol;
                            this.usuarioModel.NombreEstadoUsuario = response.datos.NombreEstadoUsuario;
                            this.usuarioModel.NombrePersonal = response.datos.NombrePersonal;
                            this.usuarioModel.NombreUsuario = response.datos.NombreUsuario;
                            this.usuarioModel.Imagen = response.datos.Imagen;
                            this.usuarioModel.autenticado = true;

                            localStorage.setItem('UsuarioActual', JSON.stringify(this.usuarioModel));
                            localStorage.setItem('UsuarioToken', this.token);

                            this.usuarioActualSubject.next(this.usuarioModel);
                            return this.usuarioModel;
                        } else {
                            this.usuarioModel = new UsuarioModel();
                            this.usuarioModel.autenticado = false;
                            this.usuarioModel.mensaje = response.mensaje;
                            return this.usuarioModel;
                        }
                    }, (error) => {
                        this.errorService.handleError(error);
                    }
                )
            );
    }

    public recuperarContrasena(correo: string) {
        const url = this.apiUrl + '/restaurar-contrasena';
	
        return this.http.get<GenericResponse>(url, {
            headers: new HttpHeaders().set('content-type', 'application/json'),
            params: {
                'correoElectronico': correo
            }
        }).pipe(
            map(
                (response: GenericResponse) => {
                    return response;
                },
                (error) => {
                    this.errorService.handleError(error);
                }
            )
        );
	}


    public validarContrasena(pass: string) {
        const url = this.apiUrl + '/validar-contrasena';
        return this.http.get<GenericResponse>(url, {
            headers: new HttpHeaders().set('content-type', 'application/json'),
            params: {
                'contrasena': pass
            }
        }).pipe(
            map(
                (response: GenericResponse) => {
                    return response;
                },
                (error) => {
                    this.errorService.handleError(error);
                }
            )
        );
    }

    public actualizarContrasenaUsuario(datos: any) {
        const url = this.apiUrl + '/restaurar-contrasena';
        const body = JSON.stringify(datos);
        return this.http.put<GenericResponse>(url, body, {
            headers: new HttpHeaders().set('content-type', 'application/json')
        }).pipe(map(
            (response: GenericResponse) => {
                return response;
            },
            (error) => {
                this.errorService.handleError(error);
        }));
    }

    public obtenerUsuarioPorTokenContrasena(token: string) {
        const url = this.apiUrl + '/usuario-token-contrasena';
        return this.http
            .get<GenericResponse>(url, {
                headers: new HttpHeaders().set('content-type', 'application/json'),
                params: {
                    'tokenContrasena': token
                }
            })
            .pipe(
                map(
                    (response: GenericResponse) => {
                        return response;
                    },
                    (error) => {
                        this.errorService.handleError(error);
                    }
                )
            );
    }



    public logout() {
        localStorage.removeItem('UsuarioToken');
        localStorage.removeItem('UsuarioActual');
        this.usuarioActualSubject.next(null);
        this.router.navigateByUrl('/sesion');
    }

    public getUsuarioActual() {
        return localStorage.getItem('UsuarioActual');
    }

    public getTokenActual() {
        return localStorage.getItem('UsuarioToken');
    }

    public isLogged() {
        const token = this.getTokenActual();
        const jwt = new JwtHelperService();
        if ( (token === 'undefined' || token === null) || jwt.isTokenExpired(token) ) {
            return false;
        }
        return true;
    }
}