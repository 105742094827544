import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ErrorService } from 'src/app/@seguridad/servicios/error.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HoldOnConfig } from '../../@temas/holdOn/holdOn';
import { NotificacionesConfig } from '../../@temas/notificaciones/notificaciones';
import { VigenciaService } from '../../@servicios/vigencia/vigencia.service';
import { tarifasMaximasService } from '../../@servicios/tarifasMaximas/tarifasMaximas.services';
@Component({
  selector: 'app-editar-tarifa-max',
  templateUrl: './editar-tarifa-max.component.html',
  styleUrls: ['./editar-tarifa-max.component.scss']
})
export class EditarTarifaMaxComponent implements OnInit {
  @Input() accion: string;
  @Input() id: any;
  @Input() servicio: number;
  @Input() IdTipoTarifa: number;

  public form: FormGroup;
  public vigencias: any[];

  constructor(
    private services: tarifasMaximasService,
    private vigenciaService: VigenciaService,
    private formBuiler: FormBuilder,
    private modalActivo: NgbActiveModal,
    private notificacion: NotificacionesConfig,
    private holdOn: HoldOnConfig,
    private errorService: ErrorService,
  ) {
    this.vigencias = [];
   }

  ngOnInit(): void {
    console.log(this.IdTipoTarifa);
    this.obtenerFormulario();
  }

  async obtenerFormulario() {
    this.form = this.formBuiler.group({
      IdTarifasMaxima: [null],
      IdTipoServicio: [this.servicio, Validators.required],
      IdVigencia: [null, Validators.required],
      Z1: [null, Validators.required],
      Z2: [null, Validators.required],
      Z3: [null, Validators.required],
      Z4: [null, Validators.required],
      Z5: [null, Validators.required],
      Z6: [null, Validators.required],
      Z7: [null, Validators.required],
      IdTipoTarifa: [this.IdTipoTarifa, Validators.required]
    });

    this.obtenerInformacion();
  }

  async obtenerInformacion() {
    this.holdOn.open('Cargando..!');
    await Promise.all(
      [
        this.vigenciaService.get().toPromise()
      ]
    ).then(async resultado => {
      this.vigencias = resultado[0].datos;
    });
    this.accion == 'EDIT' ? this.obtener() : null;
    this.holdOn.close();
  }

  async obtener() {
    const respuesta = await this.services.getxId(this.id).toPromise();
    this.form.patchValue({
      IdTarifasMaxima: respuesta.datos.IdTarifasMaxima,
      IdTipoServicio: respuesta.datos.IdTipoServicio,
      IdVigencia: respuesta.datos.IdVigencia,
      Z1: respuesta.datos.Z1,
      Z2: respuesta.datos.Z2,
      Z3: respuesta.datos.Z3,
      Z4: respuesta.datos.Z4,
      Z5: respuesta.datos.Z5,
      Z6: respuesta.datos.Z6,
      Z7: respuesta.datos.Z7,
      IdTipoTarifa:  respuesta.datos.IdTipoTarifa
    });
  }


  guardar() {
    if (this.accion == 'INSERT') {
      this.insertar();
    } else {
      this.actualizar();
    }
  }

  insertar() {
    this.holdOn.open('Cargando..!');
    this.services.post(this.form.value).pipe().subscribe(
      (result) => {
        if (result.exito) {
          this.notificacion.success(result.mensaje);
          this.form.reset();
          this.holdOn.close();
          this.modalActivo.close(true);
        } else {
          this.notificacion.error(result.mensaje);
          this.holdOn.close();
        }
      }, (error) => {
        this.errorService.handleError(error);
        this.holdOn.close();
      }
    );
    this.holdOn.close();
  }

  actualizar() {
    this.holdOn.open('Cargando..!');
    this.services.put(this.form.value).pipe().subscribe(
      (result) => {
        if (result.exito) {
          this.notificacion.success(result.mensaje);
          this.form.reset();
          this.holdOn.close();
          this.modalActivo.close(true);
        } else {
          this.notificacion.error(result.mensaje);
          this.holdOn.close();
        }
      }, (error) => {
        this.errorService.handleError(error);
        this.holdOn.close();
      }
    );
    this.holdOn.close();
  }

  retornarAccionATitleCase() {
    const titulo: string = this.accion.toLowerCase();
    return titulo.charAt(0).toUpperCase() + titulo.slice(1) + 'ar';
  }

  cerrarModal() {
    this.modalActivo.close(true);
  }

}
