<div class="modal-header">
  <h5 class="modal-title">Eliminar {{retornarTitulo()}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="cerrarModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  ¿Desea eliminar {{retornarTitulo()}} del sistema?

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="eliminarRegistro()" *ngIf="!nombreFuncion">Confirmar</button>

  <button type="button" class="btn btn-primary" (click)="eliminarRegistroFuncionCustom()"
    *ngIf="nombreFuncion">Confirmar</button>

  <button #borrar type="button" class="btn btn-secondary" ngbAutoFocus (click)="cerrarModal()"
    data-dismiss="modal">Cancelar</button>
</div>