<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Perfil de usuario</h5>
  <button type="button" class="close" data-dismiss="modal" (click)="cerrarModal()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="form form-row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="form-label">Nombre de usuario:<small>(*)</small></label><br>
        <input type="text" class="form-control" formControlName="NombreUsuario"
          placeholder="Ingrese el nombre de usuario">
        <div
          *ngIf="form.get('NombreUsuario').invalid && (form.get('NombreUsuario').dirty || form.get('NombreUsuario').touched)">
          <div class="text-danger" *ngIf="form.get('NombreUsuario').hasError('required')">
            El campo es <strong>requerido</strong>
          </div>
          <div class="text-danger" *ngIf="form.get('NombreUsuario').hasError('maxlength')">
            Máximo <strong>150</strong> caracteres
          </div>
          <div class="text-danger" *ngIf="form.get('NombreUsuario').hasError('minlength')">
            Mínimo <strong>2</strong> caracteres
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Nombre de personal:<small>(*)</small></label><br>
        <input type="text" class="form-control" formControlName="NombrePersonal"
          placeholder="Ingrese el nombre personal">
        <div
          *ngIf="form.get('NombrePersonal').invalid && (form.get('NombrePersonal').dirty || form.get('NombrePersonal').touched)">
          <div class="text-danger" *ngIf="form.get('NombrePersonal').hasError('required')">
            El campo es <strong>requerido</strong>
          </div>
          <div class="text-danger" *ngIf="form.get('NombrePersonal').hasError('maxlength')">
            Máximo <strong>150</strong> caracteres
          </div>
          <div class="text-danger" *ngIf="form.get('NombrePersonal').hasError('minlength')">
            Mínimo <strong>2</strong> caracteres
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Correo electrónico:<small>(*)</small></label><br>
        <input type="text" class="form-control" formControlName="CorreoElectronico" required
          placeholder="Ingrese el correo electrónico" maxlength="50">
        <div
          *ngIf="form.get('CorreoElectronico').invalid && (form.get('CorreoElectronico').dirty || form.get('CorreoElectronico').touched)">
          <div class="text-danger" *ngIf="form.get('CorreoElectronico').hasError('required')">
            El campo es <strong>requerido</strong>
          </div>
          <div class="text-danger" *ngIf="form.get('CorreoElectronico').hasError('pattern')">
            Correo <strong>invalido</strong>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Teléfono:</label><br>
        <input type="text" class="form-control" formControlName="Telefono" mask="0000-0000" minlength="9"
          placeholder="Ingrese el número teléfonico" autocomplete="off">
        <div *ngIf="form.get('Telefono').invalid && (form.get('Telefono').dirty || form.get('Telefono').touched)">
          <div class="text-danger" *ngIf="form.get('Telefono').hasError('minLength')">
            Digite un numero de teléfono <strong>valido</strong>
          </div>
        </div>
      </div>


      <div class="form-group">
        <label class="form-label">Rol:<small>(*)</small></label><br>
        <input type="text" class="form-control" value="{{rol}}" disabled>
      </div>


    </div>
    <div class="col-md-6">
      <div>
        <img src="{{pathImagen == ''? 'assets/imagenes/user.jpg': pathImagen}}" style="width: 100%;">
      </div>
      <div style="margin: 10px 0px; text-align: center;">
        <input type="file" #archivo placeholder="Agregar archivo" (change)="uploadFile(archivo.files)"
          accept="image/x-png,image/jpeg" style="display:none;">
        <button type="button" class="btn btn-primary" (click)="archivo.click()">Subir imagen</button>
        <button type="button" class="btn btn-danger" (click)="eliminarImagen()">Eliminar imagen</button>
      </div>
    </div>

  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="actualizar()">Guardar</button>
  <button type="button" class="btn btn-secondary" (click)="cerrarModal()" #botonCancelar
    data-dismiss="modal">Cancelar</button>
</div>
