import { Injectable } from '@angular/core';
import { NotificacionesConfig } from '../../@temas/notificaciones/notificaciones';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    constructor(private notificacion: NotificacionesConfig) { }

    handleError(error: any) {
        const datoError = this.obtenerMensaje(error);
        this.notificacion.warning(datoError.mensaje);
    }

    obtenerMensaje(error: any) {
        let mensaje: string;

        let titulo = 'Error';

        switch (error.status) {
            case 500:
                titulo = 'Error de servidor';
                mensaje = 'Error de servidor.';
                break;
            case 404:
                titulo = 'Error de solicitud';
                mensaje = 'No se ha encontrado el recurso.';
                break;
            case 401:
                titulo = 'Error de permisos';
                mensaje = 'No posee los permisos suficientes para realizar esta acción.';
                break;
            default:
                mensaje = error.message;
                break;
        }

        return {
            mensaje,
            titulo
        };
    }
}