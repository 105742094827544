import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AutenticacionService } from './autenticacion.service';
import { Menu } from '../../modulos/modulos-menu';
import { environment } from 'src/environments/environment';

@Injectable()
export class PermisosService {

    private url = environment.apiUrl;
    private sessionExpired: boolean;
    private menuPrincipal: Menu[];
    private menuSecundario: Menu[];
    private permisos: any[];

    constructor(
        private http: HttpClient,
        private injector: Injector
    ) { }

    public get auth(): AutenticacionService {
        return this.injector.get(AutenticacionService);
    }

    public setSessionExpired(expired: boolean) {
        this.sessionExpired = expired;
    }

    public setMenu(menu) {
        this.menuPrincipal = menu.MenuPrincipal;
        this.menuSecundario = menu.MenuSecundario;
    }

    public setPermisos(permisos) {
        this.permisos = permisos;
    }


    public getSessionExpired() {
        return this.sessionExpired;
    }

    public getMenuPrincipal() {
        return this.menuPrincipal;
    }

    public getMenuSecundario() {
        return this.menuSecundario;
    }

    public getPermisos() {
        return this.permisos;
    }

    public obtenerMenu() {
        return this.http.get(this.url + '/menu/nodos').toPromise();
    }

    public ObtenerPermisos() {
        const usuario = JSON.parse(this.auth.getUsuarioActual());
        return this.http.get(this.url + '/permiso?IdUsuario=' + usuario.IdUsuario).toPromise();
    }

    public logout() {
        this.auth.logout();
    }

    public comprobarPermiso(permiso: string) {
        const resultado = this.getPermisos().filter(e => e.IdPermiso == permiso);
        if (resultado.length > 0) {
            return true;
        } 
        return false;
    }


}