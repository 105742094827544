import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorService } from '../../@seguridad/servicios/error.service';
import { environment } from '../../../environments/environment';
import { GenericResponse } from '../../modelos/GenericResponse/GenericResponse.model';

@Injectable()
export class EstadosUsuariosService {
    private url = environment.apiUrl + '/estado-usuario';
    private body: any;

    constructor(
        private http: HttpClient,
        private errorService: ErrorService
    ) { }

    get(): Observable<GenericResponse> {
        return this.http.get<any>(this.url).pipe(
            map(
                (result: GenericResponse) => {
                    return result;
                },
                (error: any) => {
                    this.errorService.handleError(error);
                }
            )
        );
    }

}