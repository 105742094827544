<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{retornarAccionATitleCase()}} tarifa máxima</h5>
  <button type="button" class="close" data-dismiss="modal" (click)="cerrarModal()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="form row">

    <div class="form-group col-md-8">
      <label class="form-label">Vigencia:<small>(*)</small></label><br>
      <ng-select [searchable]="true" formControlName="IdVigencia" placeholder="Seleccione la vigencia"
        notFoundText="No se ha encontrado ninguna opción">
        <ng-option *ngFor="let item of vigencias" [value]="item.IdVigencia">{{item.NoVigencia +" Año:"+ item.Anio}}</ng-option>
      </ng-select>
    </div>

    <div class="form-group col-md-3">
      <label class="form-label">Zona 1:<small>(*)</small></label><br>
      <input type="number" class="form-control" formControlName="Z1">
    </div>

    <div class="form-group col-md-3">
      <label class="form-label">Zona 2:<small>(*)</small></label><br>
      <input type="number" class="form-control" formControlName="Z2">
    </div>

    <div class="form-group col-md-3">
      <label class="form-label">Zona 3:<small>(*)</small></label><br>
      <input type="number" class="form-control" formControlName="Z3">
    </div>

    <div class="form-group col-md-3">
      <label class="form-label">Zona 4:<small>(*)</small></label><br>
      <input type="number" class="form-control" formControlName="Z4">
    </div>

    <div class="form-group col-md-3">
      <label class="form-label">Zona 5:<small>(*)</small></label><br>
      <input type="number" class="form-control" formControlName="Z5">
    </div>

    <div class="form-group col-md-3">
      <label class="form-label">Zona 6:<small>(*)</small></label><br>
      <input type="number" class="form-control" formControlName="Z6">
    </div>

    <div class="form-group col-md-3">
      <label class="form-label">Zona 7:<small>(*)</small></label><br>
      <input type="number" class="form-control" formControlName="Z7">
    </div>

  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="guardar()">Guardar</button>
  <button type="button" class="btn btn-secondary" (click)="cerrarModal()" #botonCancelar
    data-dismiss="modal">Cancelar</button>
</div>
