import { Injectable } from '@angular/core';
declare var HoldOn: any;

@Injectable()
export class HoldOnConfig {

    open(mensaje: string) {
        HoldOn.open({
            theme: 'sk-circle',
            message: mensaje,
			backgroundColor:"#f4f3ef",
			textColor:"black"
        });
    }

    close() {
        HoldOn.close();
    }

}