import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './sidebar.component';
import { UsuariosService } from '../../../@servicios/usuarios/usuarios.service';
import { RolService } from '../../../@servicios/rol/rol.service';
import { EstadosUsuariosService } from '../../../@servicios/estado-usuario/estado-usuario.service';
import { PerfilComponent } from './perfil/perfil.component';
@NgModule({
    imports: [ RouterModule, CommonModule, ReactiveFormsModule, FormsModule],
    declarations: [ SidebarComponent, PerfilComponent ],
    exports: [ SidebarComponent ],
    providers: [UsuariosService, RolService, EstadosUsuariosService],
    entryComponents: [PerfilComponent]
})

export class SidebarModule {}
