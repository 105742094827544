<div class="modal-header">
  <h5 class="modal-title">{{titulo}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="cerrarModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  {{body}}
</div>

<div class="modal-footer">
  <button #btnConfirmar type="button" class="btn btn-primary" (click)="cerrarModal(true)">{{confirmar}}</button>

  <button #btnCancelar type="button" class="btn btn-secondary" (click)="cerrarModal(false)"
    data-dismiss="modal">{{cancelar}}</button>
</div>